.eva-file-attach-field .blockIcon {
  font-size: 20px;
  width: 35px;
}
.eva-file-attach-field .blocktext {
  width: calc(100% - 100px);
  cursor: pointer;
}
.eva-file-attach-field .sizeFile {
  width: 65px;
  text-align: end;
}
.eva-file-attach-field .eva-file-attach-field-btn {
  width: 100%;
  margin-top: 12px;
}
.eva-file-attach-field .eva-file-attach-field-files {
  display: flex;
  flex-direction: column;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 5px 0px;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-file .closeBtn {
  font-size: 1.2rem;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-file .closeBtn:hover {
  cursor: pointer;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-file .eva-file-attach-field-files-file-name {
  display: flex;
  align-items: center;
  width: calc(100% - 65px);
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-images {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-images .eva-file-attach-field-files-file {
  width: 100px;
  min-height: 76px;
  position: relative;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-images .eva-file-attach-field-files-file .eva-file-attach-field-files-file-delete-btn {
  position: absolute;
  right: -5px;
  top: -5px;
  border-radius: 50%;
  background-color: #495a6f;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-images .eva-file-attach-field-files-file .eva-file-attach-field-files-file-delete-btn:hover {
  cursor: pointer;
}
