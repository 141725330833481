
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-loader {
  z-index: 10000;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #212d3986;
  display: flex;
  align-items: center;
  justify-content: space-around;

}
