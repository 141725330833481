.etvContainer {
  margin: 10px;
  width: 100%;
  height: 100%;
}
.etvContainer .etv-row {
  display: flex;
  width: 100%;
  gap: 5px;
  margin-bottom: 15px;
}
.etvContainer .etv-row .etv-col {
  flex: 1;
  /* для обрезки текста */
  overflow: hidden;
  word-wrap: break-word;
}
.etvContainer .etv-row:last-child {
  margin-bottom: 1px;
}
