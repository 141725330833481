.eva-btn-commands {
  @media @minHover {
    &:hover {
      background-color: @cBlueHover;
    }
  }

  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: @border-radius 0 0 @border-radius;
  border: 1px solid transparent;
  padding: @btnPadding;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  text-align: center;
  font-size: 14px;
  font-family: inherit;
  line-height: 16px;
  text-decoration: none;
  color: @cWhite;
  background-color: @cBlue;
  background-image: none;
  cursor: pointer;
  overflow: hidden;
  transition: all @transition-time;
  font-weight: 600;

  &__icon {
    width: 16px;
    height: 16px;
    display: block;

    & + * {
      margin-left: 10px;
    }
  }

  &:active,
  &:focus {
    background-color: @cBluePressed;
  }

  &--disabled,
  &:disabled {
    opacity: 0.5;
    border-color: @cBlue;
    cursor: default;
    pointer-events: none;
  }

  &--large {
    padding: @btnLargePadding;
    min-width: 48px;
  }

  &--small {
    padding: @btnSmallPadding;
    min-width: 34px;
  }

  &--success {
    @media @minHover {
      &:hover {
        background-color: @cSuccessHover;
      }
    }
    background-color: @cSuccess;

    &:active,
    &:focus {
      background-color: @cSuccessPressed;
    }

    &--disabled,
    &:disabled {
      background-color: @cSuccessDisabled;
      border-color: @cSuccessDisabledBorder;
    }
  }

  &--error {
    @media @minHover {
      &:hover {
        background-color: @cErrorHover;
      }
    }
    background-color: @cError;

    &:active,
    &:focus {
      background-color: @cErrorPressed;
    }

    &--disabled,
    &:disabled {
      background-color: @cErrorDisabled;
      border-color: @cErrorDisabledBorder;
    }
  }

  &--secondary {
    @media @minHover {
      &:hover {
        background-color: #5E7694;
        border-color: #5E7694;
      }
    }

    background-color: #576D88;
    border-color: #576D88;
  }

  &--flat {
    @media @minHover {
      &:hover {
        border-color: @cLight;
        background-color: transparent;
      }
    }

    background-color: transparent;
    border-color: transparent;

    &:active,
    &:focus {
      border-color: @cLight;
      background-color: transparent;
      color: @cBlue;
    }

    &--disabled,
    &:disabled {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &--flat-text-blue {
    padding: 0;
    border-radius: 0;
    background: transparent;
    color: @cBlue;
    height: 16px;

    @media @minHover {
      &:hover {
        border-color: transparent;
        color: @cBlueHover;
        background-color: transparent;
      }
    }
  }
}

.eva-btn-commands-list {
  background-color: @cBlue;
  border-radius: 0 @border-radius @border-radius 0;
  max-width: 20%;

  &:active,
  &:focus {
    background-color: @cBluePressed;
  }

  &--secondary {
    @media @minHover {
      &:hover {
        background-color: #5E7694;
      }
    }

    background-color: #576D88;
    border-color: #576D88;

    &:active,
    &:focus {
      background-color: #495A6F;
    }
  }
}

a.eva-btn-commands--disabled {
  pointer-events: none;
}
