.bodyFile {
  width: 100%;
}
.bodyFile .label {
  margin-top: 25px;
}
.bodyFile .itemFile {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 5px 0;
  color: #ACBBCB;
  cursor: pointer;
}
.bodyFile .itemFile .blockItemName {
  display: flex;
  align-items: center;
  width: calc(100% - 64px);
}
.bodyFile .itemFile .blockItemName .icons {
  font-size: 20px;
  width: 20px;
}
.bodyFile .itemFile .blockItemName .nameFile {
  margin-left: 5px;
  width: calc(100% - 30px);
  word-wrap: break-word;
}
.bodyFile .itemFile .sizeFile {
  display: flex;
  align-items: center;
  color: #7B8B9D;
  white-space: nowrap;
  width: 65px;
}
.loader {
  width: 100%;
  height: 2px;
  /* Серый */
  position: relative;
  overflow: hidden;
}
.progress {
  width: 0;
  height: 100%;
  background-color: #0094FF;
  /* Синий */
  animation: progress 2s linear infinite;
}
@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.loaderRW {
  position: absolute;
  background-color: #3e4c5db3;
  width: 100%;
  height: 100%;
  z-index: 10;
}
