
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.etvContainer {
  margin: 10px;
  width: 100%;
  height: 100%;

  .etv-row {
    display: flex;
    width: 100%;
    gap: 5px;
    margin-bottom: 15px;

    .etv-col {
      flex: 1;
      /* для обрезки текста */
      overflow: hidden;
      word-wrap: break-word;
    }

  }

  .etv-row:last-child {
    margin-bottom: 1px;
  }
}
